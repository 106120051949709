/*------ Mail -------*/
.mail-option {
	display: inline-block;
	margin-bottom: 10px;
	width: 100%;
	.btn-group, .chk-all {
		margin-right: 5px;
	}
	.btn-group a.btn, .chk-all {
		background: none repeat scroll 0 0 $body-color;
		border: 1px solid #e1ecfc;
		border-radius: 3px !important;
		color: #3d4e67;
		display: inline-block;
		padding: 5px 10px;
	}
}
.inbox-pagination a.np-btn {
	background: none repeat scroll 0 0 $body-color;
	border: 1px solid #e1ecfc;
	border-radius: 3px !important;
	color: #3d4e67;
	display: inline-block;
	padding: 5px 15px;
	margin-left: 5px;
}
.mail-option {
	.chk-all input[type=checkbox] {
		margin-top: 0;
	}
	.btn-group a.all {
		border: medium none;
		padding: 0;
	}
}
.inbox-pagination li span {
	display: inline-block;
	margin-right: 5px;
	margin-top: 7px;
}
ul.inbox-pagination {
	float: right;
	li {
		float: left;
	}
}
.view-mail a {
	color: #ff6c60;
}
.attachment-mail {
	margin-top: 30px;
	ul {
		display: inline-block;
		margin-bottom: 30px;
		width: 100%;
		li {
			float: left;
			margin-bottom: 10px;
			margin-right: 10px;
			width: 150px;
			img {
				width: 100%;
			}
			span {
				float: right;
			}
		}
	}
	.file-name {
		float: left;
	}
	.links {
		display: inline-block;
		width: 100%;
	}
}

a.mail-dropdown {
	background: none repeat scroll 0 0 #80d3d9;
	border-radius: 2px;
	color: #01a7b3;
	font-size: $default-value-size * 10;
	margin-top: 20px;
	padding: 3px 5px;
}
.mail-chats {
	height: 100%;
	min-height: 0;
	border-top: 1px solid $border-color;
	margin-top: 10px;
	padding: 10px 50px 10px 0;
	width: 100%;
	list-style-type: none;
	flex-direction: column !important;
	display: flex !important;
	li.chat-persons {
		padding: 10px;
		display: block;
	}
}
li.chat-persons a {
	text-decoration: none;
}
.mail-chats li.chat-persons a {
	span.pro-pic {
		img {
			max-width: 100%;
			width: 100%;
			-webkit-border-radius: 100%;
			-moz-border-radius: 100%;
			-ms-border-radius: 100%;
			border-radius: 100%;
			flex-direction: column !important;
		}
		display: inline-block;
		padding: 0;
		width: 20%;
		max-width: 40px;
		float: left;
		margin-right: 20px;
	}
	div.user {
		flex-direction: column !important;
	}
}
ul.mail-chats li.chat-persons a div.user {
	display: flex !important;
}
.mail-chats li.chat-persons.user {
	width: 80%;
	padding: 5px 10px 0 15px;
	flex-direction: column !important;
	display: flex !important;
}

ul.inbox-nav {
	display: inline-block;
	margin: 0;
	padding: 0;
	width: 100%;
}
.inbox-divider {
	border-bottom: 1px solid #d5d8df;
}
ul {
	&.inbox-nav li {
		display: inline-block;
		line-height: 45px;
		width: 100%;
		a {
			color: #6a6a6a;
			display: inline-block;
			line-height: 45px;
			padding: 0 20px;
			width: 100%;
			&:focus, &:hover {
				background: none repeat scroll 0 0 #d5d7de;
				color: #6a6a6a;
			}
		}
		&.active a {
			background: none repeat scroll 0 0 #d5d7de;
			color: #6a6a6a;
		}
		a {
			i {
				color: #6a6a6a;
				font-size: $default-value-size * 16;
				padding-right: 10px;
			}
			span.label {
				margin-top: 13px;
			}
		}
	}
	&.labels-info li {
		h4 {
			color: #5c5c5e;
			font-size: $default-value-size * 13;
			padding-left: 15px;
			padding-right: 15px;
			padding-top: 5px;
			text-transform: uppercase;
		}
		margin: 0;
		a {
			border-radius: 0;
			color: #6a6a6a;
			&:focus, &:hover {
				background: none repeat scroll 0 0 #d5d7de;
				color: #6a6a6a;
			}
			i {
				padding-right: 10px;
			}
		}
	}
}

.inbox-head {
	border-radius: 0 4px 0 0;
	padding: 10px;
	border-radius: 5px;
	h3 {
		display: inline-block;
		font-weight: $font-weight-normal;
		margin: 0;
	}
	.sr-input {
		border: medium none;
		border-radius: 4px 0 0 4px;
		box-shadow: none;
		color: #8a8a8a;
		float: left;
		height: 30px;
		padding: 0 10px;
	}
	.sr-btn {
		background: none repeat scroll 0 0 #eef2f9;
		border: medium none;
		border-radius: 0 4px 4px 0;
		color: $white;
		height: 30px;
		padding: 0 20px;
	}
}
.heading-inbox h4 {
	border-bottom: 1px solid #ddd;
	color: #444;
	font-size: $default-value-size * 18;
	margin-top: 20px;
	padding-bottom: 10px;
}
.sender-info {
	margin-bottom: 20px;
	img {
		height: 30px;
		width: 30px;
	}
}
.sender-dropdown {
	background: none repeat scroll 0 0 $border-color;
	color: #777;
	font-size: $default-value-size * 10;
	padding: 0 3px;
}
.fileinput-button {
	float: left;
	margin-right: 4px;
	overflow: hidden;
	position: relative;
	background: none repeat scroll 0 0 $border-color;
	border: 1px solid #e6e6e6;
	input {
		cursor: pointer;
		direction: ltr;
		font-size: $default-value-size * 23;
		margin: 0;
		opacity: 0;
		position: absolute;
		right: 0;
		top: 0;
		transform: translate(-300px, 0) scale(4);
	}
}
.fileupload-buttonbar {
	.btn, .toggle {
		margin-bottom: 5px;
	}
}
.files .progress {
	width: 200px;
}
.fileupload-processing .fileupload-loading {
	display: block;
}
* html .fileinput-button {
	line-height: 24px;
	margin: 1px -3px 0 0;
}
.user-head {
	.inbox-avatar {
		float: left;
		width: 65px;
		img {
			border-radius: 4px;
		}
	}
	.user-name {
		display: inline-block;
		margin: 0 0 0 10px;
		h5 {
			font-size: $default-value-size * 14;
			font-weight: $font-weight-normal;
			margin-bottom: 0;
			margin-top: 15px;
			a {
				color: $white;
			}
		}
		span a {
			color: #87e2e7;
			font-size: $default-value-size * 12;
		}
	}
}
.inbox-message {
	ul {
		padding: 0;
		margin: 0;
		li {
			list-style: none;
			position: relative;
			padding: 15px 20px;
			border-bottom: 1px solid $border-color;
			&:focus, &:hover {
				background: $border-color;
			}
		}
	}
	.message-avatar {
		position: absolute;
		left: 30px;
		top: 50%;
		transform: translateY(-50%);
	}
}
.message-avatar img {
	display: inline-block;
	width: 54px;
	height: 54px;
	border-radius: 50%;
}
.inbox-message .message-body {
	margin-left: 85px;
	font-size: $default-value-size * 15;
	color: #cecde0;
}
.message-body-heading h5 {
	font-weight: $font-weight-semibold2;
	display: inline-block;
	color: #cecde0;
	margin: 0 0 7px 0;
	padding: 0;
}
.message-body h5 span {
	border-radius: 50px;
	line-height: 14px;
	font-size: $default-value-size * 12;
	color: $white;
	font-style: normal;
	padding: 4px 10px;
	margin-left: 5px;
	margin-top: -5px;
	&.unread {
		background: #07b107;
	}
	&.important {
		background: #dd2027;
	}
	&.pending {
		background: #2196f3;
	}
}
.message-body-heading span {
	float: right;
	font-size: $default-value-size * 14;
}
.messages-inbox .message-body p {
	margin: 0;
	padding: 0;
	line-height: 27px;
	font-size: $default-value-size * 15;
}
/*------ Mail -------*/
/*-------- Typhography -------*/
.small, small{
	font-size: 85%;
    font-weight: $font-weight-normal;
}
.tracking-tight {
	letter-spacing: -.05em !important;
}
.tracking-normal {
	letter-spacing: 0 !important;
}
.tracking-wide {
	letter-spacing: .05em !important;
}
.leading-none {
	line-height: 1 !important;
}
.leading-tight {
	line-height: 1.3 !important;
}
.leading-normal {
	line-height: 1.5 !important;
}
.leading-normal-2 {
	line-height: 1.8 !important;
}
.leading-loose {
	line-height: 2 !important;
}

/*-------- Font styles -------*/

.font-weight-light {
	font-weight: $font-weight-light;
}
.font-weight-normal {
	font-weight: $font-weight-normal;
}
.font-weight-semibold {
	font-weight: $font-weight-semibold;
}
.font-weight-semibold2 {
	font-weight: $font-weight-semibold2;
}
.font-weight-bold {
	font-weight: $font-weight-bold;
}
.font-italic {
	font-style: italic !important;
}
/*--------  Typhography  -------*/
/*------ Grid -------*/
%padding-values{
	padding-right: .75rem;
    padding-left: .75rem;
}
.container, .container-fluid, .container-lg, .container-md, .container-sm, .container-xl{
	@extend %padding-values;
}
.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col, .col-auto, .col-sm-1, .col-sm-2, .col-sm-3,
.col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm, .col-sm-auto, .col-md-1, .col-md-2, .col-md-3,
.col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md, .col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3,
.col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg, .col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3,
.col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl, .col-xl-auto,  .col-xxl-1, .col-xxl-2, .col-xxl-3,
.col-xxl-4, .col-xxl-5, .col-xxl-6, .col-xxl-7, .col-xxl-8, .col-xxl-9, .col-xxl-10, .col-xxl-11, .col-xxl-12, .col-xxl, .col-xxl-auto{
	@extend %padding-values;
}
@media (min-width: 1280px) {
	.container {
		max-width: 1200px;
	}
}
@media (min-width: 1400px) {
	.col-xxl {
		-ms-flex-preferred-size: 0;
		flex-basis: 0;
		-ms-flex-positive: 1;
		flex-grow: 1;
		max-width: 100%;
	}
	.col-xxl-auto {
		-ms-flex: 0 0 auto;
		flex: 0 0 auto;
		width: auto;
		max-width: none;
	}
	.col-xxl-1 {
		-ms-flex: 0 0 8.33333333%;
		flex: 0 0 8.33333333%;
		max-width: 8.33333333%;
	}
	.col-xxl-2 {
		-ms-flex: 0 0 16.66666667%;
		flex: 0 0 16.66666667%;
		max-width: 16.66666667%;
	}
	.col-xxl-3 {
		-ms-flex: 0 0 25%;
		flex: 0 0 25%;
		max-width: 25%;
	}
	.col-xxl-4 {
		-ms-flex: 0 0 33.33333333%;
		flex: 0 0 33.33333333%;
		max-width: 33.33333333%;
	}
	.col-xxl-5 {
		-ms-flex: 0 0 41.66666667%;
		flex: 0 0 41.66666667%;
		max-width: 41.66666667%;
	}
	.col-xxl-6 {
		-ms-flex: 0 0 50%;
		flex: 0 0 50%;
		max-width: 50%;
	}
	.col-xxl-7 {
		-ms-flex: 0 0 58.33333333%;
		flex: 0 0 58.33333333%;
		max-width: 58.33333333%;
	}
	.col-xxl-8 {
		-ms-flex: 0 0 66.66666667%;
		flex: 0 0 66.66666667%;
		max-width: 66.66666667%;
	}
	.col-xxl-9 {
		-ms-flex: 0 0 75%;
		flex: 0 0 75%;
		max-width: 75%;
	}
	.col-xxl-10 {
		-ms-flex: 0 0 83.33333333%;
		flex: 0 0 83.33333333%;
		max-width: 83.33333333%;
	}
	.col-xxl-11 {
		-ms-flex: 0 0 91.66666667%;
		flex: 0 0 91.66666667%;
		max-width: 91.66666667%;
	}
	.col-xxl-12 {
		-ms-flex: 0 0 100%;
		flex: 0 0 100%;
		max-width: 100%;
	}
	.order-xxl-first {
		-ms-flex-order: -1;
		order: -1;
	}
	.order-xxl-last {
		-ms-flex-order: 13;
		order: 13;
	}
	.order-xxl-0 {
		-ms-flex-order: 0;
		order: 0;
	}
	.order-xxl-1 {
		-ms-flex-order: 1;
		order: 1;
	}
	.order-xxl-2 {
		-ms-flex-order: 2;
		order: 2;
	}
	.order-xxl-3 {
		-ms-flex-order: 3;
		order: 3;
	}
	.order-xxl-4 {
		-ms-flex-order: 4;
		order: 4;
	}
	.order-xxl-5 {
		-ms-flex-order: 5;
		order: 5;
	}
	.order-xxl-6 {
		-ms-flex-order: 6;
		order: 6;
	}
	.order-xxl-7 {
		-ms-flex-order: 7;
		order: 7;
	}
	.order-xxl-8 {
		-ms-flex-order: 8;
		order: 8;
	}
	.order-xxl-9 {
		-ms-flex-order: 9;
		order: 9;
	}
	.order-xxl-10 {
		-ms-flex-order: 10;
		order: 10;
	}
	.order-xxl-11 {
		-ms-flex-order: 11;
		order: 11;
	}
	.order-xxl-12 {
		-ms-flex-order: 12;
		order: 12;
	}
	.offset-xxl-0 {
		margin-left: 0;
	}
	.offset-xxl-1 {
		margin-left: 8.33333333%;
	}
	.offset-xxl-2 {
		margin-left: 16.66666667%;
	}
	.offset-xxl-3 {
		margin-left: 25%;
	}
	.offset-xxl-4 {
		margin-left: 33.33333333%;
	}
	.offset-xxl-5 {
		margin-left: 41.66666667%;
	}
	.offset-xxl-6 {
		margin-left: 50%;
	}
	.offset-xxl-7 {
		margin-left: 58.33333333%;
	}
	.offset-xxl-8 {
		margin-left: 66.66666667%;
	}
	.offset-xxl-9 {
		margin-left: 75%;
	}
	.offset-xxl-10 {
		margin-left: 83.33333333%;
	}
	.offset-xxl-11 {
		margin-left: 91.66666667%;
	}
}

.row-sm>div {
	padding-left: 7px;
	padding-right: 7px;
}
/*------ Custom Grid -------*/
/*-------- Charts -------*/
.chart-circle {
	display: block;
	height: 8rem;
	width: 8rem;
	position: relative;
	margin: 0 auto;
	canvas {
		margin: 0 auto;
		display: block;
		max-width: 100%;
		max-height: 100%;
	}
}
.chart-circle-xs {
	height: 2.5rem;
	width: 2.5rem;
	font-size: .8rem;
}
.chart-circle-xs1 {
	height: 110px;
	width: 110px;
	font-size: .8rem;
}
.chart-circle-sm {
	height: 4rem;
	width: 4rem;
	font-size: .8rem;
}
.chart-circle-lg {
	height: 10rem;
	width: 10rem;
	font-size: .8rem;
}
.chart-circle-value {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	margin-left: auto;
	margin-right: auto;
	bottom: 0;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-pack: center;
	justify-content: center;
	-ms-flex-align: center;
	align-items: center;
	-ms-flex-direction: column;
	flex-direction: column;
	line-height: 1;
	small {
		display: block;
		color: #cecde0;
		font-size: .9375rem;
	}
}
/*-------- Chart Heights -------*/

.chart-visitors {
	min-height: 18rem;
}
.chart-tasks {
	height: 15rem;
}
.chart-donut, .chart-pie {
	height: 21rem;
}
.chartsh {
	height: 16rem;
}
.chartwidget {
	height: 17rem;
}
.chartmorris {
	height: 19rem;
}
.dropshadow {
	-webkit-filter: drop-shadow(0 -6px 4px rgb(227, 232, 239));
	filter: drop-shadow(0 -6px 4px rgb(227, 232, 239));
}
/*-------- Chart Heights -------*/


.sparkline {
	display: inline-block;
	height: 2rem;
}
.jqstooltip {
	box-sizing: content-box;
	font-family: inherit !important;
	background: $text-color !important;
	border: none !important;
	border-radius: 5px;
	font-size: $default-value-size * 11 !important;
	font-weight: $font-weight-bold !important;
	line-height: 1 !important;
	padding: 6px !important;
	.jqsfield {
		font: inherit !important;
	}
}
.sparkgraph {
	display: inline-block;
	vertical-align: top;
	width: 100%;
	height: 43px;
	canvas {
		width: 100% !important;
	}
}
svg {
	-ms-touch-action: none;
	touch-action: none;
}
/*-------- Charts -------*/
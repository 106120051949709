/*-------- Widgets -------*/
.wideget-user-desc {
	.wideget-user-img img {
		border-radius: 100%;
	}
	.user-wrap {
		margin-top: 1.5rem;
	}
}
.wideget-user .wideget-user-icons a {
	display: inline-block;
	width: 2rem;
	height: 2rem;
	text-align: center;
	border-radius: 100px;
	line-height: 2rem;
	margin-top: .3rem;
	background: $border-color;
	&:hover {
		color: $white;
	}
}
.wideget-user-info {
	.wideget-user-warap {
		display: flex;
		h4 {
			font-size: 1.5rem;
			font-weight: $font-weight-semibold;
			margin-bottom: .4rem;
		}
		.wideget-user-warap-r {
			margin-left: 10rem;
		}
	}
	.wideget-user-rating a {
		font-size: $default-value-size * 18;
	}
}
.wideget-user-tab .tab-menu-heading {
	padding: 0;
	border: 0;
	.nav li a {
		color: $text-color;
		font-size: $default-value-size * 15;
		font-weight: $font-weight-semibold;
		margin-bottom: -1px;
		margin-left: 0;
		border: 0;
	}
}
.wideget-user-tab .tabs-menu1 ul li {
	a {
		padding: 8px 17px 8px 17px;
		display: block;
		background: $border-color;
		border: 0;
		margin-right: 5px;
		border-radius: 4px 4px 0 0;
	}
	display: block;
}
.wideget-user-tab .tabs-menu1 ul li .active {
	border: 0;
	border-radius: 5px 5px 0 0;
}
.wideget-user-tab3 .tab-menu-heading .nav li a i.fe {
	font-size: $default-value-size * 16;
	margin-right: 4px;
	line-height: 1.5;
}
.widget-user {
	.widget-user-header {
		padding: 20px;
		height: 120px;
	}
	.widget-user-username {
		margin-top: 0;
		margin-bottom: 5px;
		font-size: $default-value-size * 25;
		font-weight: $font-weight-light;
		text-shadow: 0 1px 1px rgba($text-color, 0.2);
		color: $white;
	}
	.widget-user-desc {
		margin-top: 0;
		color: $white;
	}
	.widget-user-image {
		position: absolute;
		top: 65px;
		left: 50%;
		margin-left: -45px;
		>img {
			width: 90px;
			height: auto;
			border: 3px solid $white;
		}
	}
	.user-wideget-footer {
		padding-top: 30px;
	}
}
.user-wideget-footer {
	border-top-left-radius: 0;
	border-top-right-radius: 0;
	border-top: 1px solid #f4f4f4;
	padding: 10px;
	background-color: $white;
}
.user-wideget .border-right {
	border-right: 1px solid #f4f4f4;
}
.user-wideget-text {
	position: relative;
	margin-bottom: -38px;
}
.widget-image img {
	width: 50px;
	height: 50px;
	border: 5px solid $white;
}
.wideget-img {
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	position: absolute;
	width: 100%;
	height: 100%;
	padding: 1.25rem;
}
.widgets-cards .widgets-cards-data {
	margin-top: 8px;
	.wrp p:first-child {
		font-size: $default-value-size * 20;
		font-weight: $font-weight-semibold2;
	}
}
.widgets {
	font-size: $default-value-size * 35;
	padding: 20px;
}
.widgetstext {
	top: 0;
	bottom: 0;
	padding: 20px 40px;
}
.widget-line h4 {
	font-size: $default-value-size * 24;
	font-weight: $font-weight-semibold2;
}
.fs {
	font-size: $default-value-size * 25;
}
.widget-line p {
	font-size: $default-value-size * 16;
}
.widget-line-list li {
	display: inline-block;
	font-size: $default-value-size * 16;
	line-height: 27px;
	padding: 5px 20px 0 15px;
	list-style-type: none;
}
.ui-widget-content {
	padding: 0 !important;
}
.widget-info {
	i {
		width: 100px;
		height: 100px;
		padding: 27px 0;
		text-align: center;
		border: 2px solid $white;
		border-radius: 50%;
	}
	a {
		border-bottom: 1px solid $white;
	}
}
.widget-spec li {
	font-size: .9rem;
	display: flex;
	padding: 9px 25px;
	border-bottom: 1px solid rgb(240, 243, 249);
	&:last-child {
		border-bottom: 0 !important;
	}
}
.widget-spec1 li {
	margin-bottom: .5rem;
	font-size: .9rem;
}
.support-list li {
	a {
		margin-bottom: .5rem;
		display: flex;
	}
	i {
		margin-right: .7rem;
		margin-top: 4px;
	}
}
.widget-spec li {
	&.icon:before {
		content: '\f00c';
		position: absolute;
		width: 100%;
		height: 100%;
		display: block;
		font-family: fontawesome;
	}
	i {
		margin-right: .5rem;
		margin-top: 3px;
	}
}
.widget-spec.tour-scroll li a{
	color:$text-color;
}
.widget-info-right {
	text-align: right;
	float: right;
}
.widget-hr {
	margin-top: 1rem;
	margin-bottom: 2rem;
}
.tour-scroll {
	height: auto !important;
	&.widget-spec li {
		padding: 15px 25px;
	}
}
.widgetdate {
	float: right;
	border-radius: 4px;
	background: $white;
	padding: 0 15px;
	p {
		margin-bottom: 0;
		margin-top: 5px;
	}
	h6 {
		font-size: $default-value-size * 22;
		font-weight: $font-weight-semibold2;
		margin-top: 0;
		margin-bottom: 5px;
	}
}
.ui-widget-header {
	border: 1px solid #2c4359;
	color: #e1e463;
	font-weight: $font-weight-bold;
	a {
		color: #e1e463;
	}
}
.ui-state-default, .ui-widget-content .ui-state-default, .ui-widget-header .ui-state-default {
	border: 1px solid $border-color;
	font-weight: $font-weight-bold;
	color: $text-color;
}
.customerpage .btn-icon {
	border: 1px solid rgba(96, 94, 126, 0.2);
}
/*-------- Widgets -------*/
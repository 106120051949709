/*------ Input group -------*/
.input-group-lg> {
	.form-control-plaintext.form-control {
		padding-right: 0;
		padding-left: 0;
	}
	.input-group-append>.form-control-plaintext {
		&.btn, &.input-group-text {
			padding-right: 0;
			padding-left: 0;
		}
	}
	.input-group-prepend>.form-control-plaintext {
		&.btn, &.input-group-text {
			padding-right: 0;
			padding-left: 0;
		}
	}
}
.input-group-sm> {
	.form-control-plaintext.form-control {
		padding-right: 0;
		padding-left: 0;
	}
	.input-group-append>.form-control-plaintext {
		&.btn, &.input-group-text {
			padding-right: 0;
			padding-left: 0;
		}
	}
	.input-group-prepend>.form-control-plaintext {
		&.btn, &.input-group-text {
			padding-right: 0;
			padding-left: 0;
		}
	}
}
.input-group-sm> {
	.form-control {
		padding: .25rem .5rem;
		font-size: .875rem;
		line-height: 1.14285714;
		border-radius: 5px;
	}
	.input-group-append> {
		.btn, .input-group-text {
			padding: .25rem .5rem;
			font-size: .875rem;
			line-height: 1.14285714;
			border-radius: 5px;
		}
	}
	.input-group-prepend> {
		.btn, .input-group-text {
			padding: .25rem .5rem;
			font-size: .875rem;
			line-height: 1.14285714;
			border-radius: 5px;
		}
	}
	.input-group-append>select {
		&.btn:not([size]):not([multiple]), &.input-group-text:not([size]):not([multiple]) {
			height: calc(1.8125rem + 2px);
		}
	}
	.input-group-prepend>select {
		&.btn:not([size]):not([multiple]), &.input-group-text:not([size]):not([multiple]) {
			height: calc(1.8125rem + 2px);
		}
	}
	select.form-control:not([size]):not([multiple]) {
		height: calc(1.8125rem + 2px);
	}
}
.input-group-lg> {
	.form-control {
		padding: .5rem 1rem;
		font-size: 1.125rem;
		line-height: 1.44444444;
		border-radius: 5px;
	}
	.input-group-append> {
		.btn, .input-group-text {
			padding: .5rem 1rem;
			font-size: 1.125rem;
			line-height: 1.44444444;
			border-radius: 5px;
		}
	}
	.input-group-prepend> {
		.btn, .input-group-text {
			padding: .5rem 1rem;
			font-size: 1.125rem;
			line-height: 1.44444444;
			border-radius: 5px;
		}
	}
	.input-group-append>select {
		&.btn:not([size]):not([multiple]), &.input-group-text:not([size]):not([multiple]) {
			height: calc(2.6875rem + 2px);
		}
	}
	.input-group-prepend>select {
		&.btn:not([size]):not([multiple]), &.input-group-text:not([size]):not([multiple]) {
			height: calc(2.6875rem + 2px);
		}
	}
	select.form-control:not([size]):not([multiple]) {
		height: calc(2.6875rem + 2px);
	}
}
.input-group-append, .input-group-btn, .input-group-prepend {
	font-size: .9375rem;
}
.input-group-append>.btn, .input-group-btn>.btn {}
.input-group-prepend> {
	.btn {}
	.input-group-text {
		border-right: 0;
	}
}
.input-group-append>.input-group-text {
	border-left: 0;
}
.input-group-btn {
	&.minus, &.add {
		color: $text-color;
		width: 20px;
		height: 20px;
		border-radius: 50%;
		border: 1px solid $border-color;
		line-height: 20px;
		font-size: $default-value-size * 12;
		margin: 0 auto;
		text-align: center;
		background: $border-color;
	}
}
/*------ Input group -------*/
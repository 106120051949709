/*------ accordion -------*/
.panel-heading1 {
	a {
		&.collapsed:before {
			content: "\e9af";
			position: absolute;
			font-family: feather !important;
			right: 24px;
			top: 24px;
			font-size: $default-value-size * 18;
			transition: all .5s;
			transform: scale(1);
		}
		&:before {
			content: "\e994";
			position: absolute;
			font-family: feather !important;
			right: 24px;
			top: 24px;
			font-size: $default-value-size * 18;
			transition: all .5s;
			transform: scale(1);
		}
	}
	&.active a:before {
		content: ' ';
		transition: all .5s;
		transform: scale(0);
	}
}
.demo-accordion p:last-child, .panel-group1 p:last-child {
	margin-bottom: 0;
}
#bs-collapse .panel-heading1 {
	a:after {
		content: "\f01a";
		font-size: $default-value-size * 24;
		position: absolute;
		font-family: FontAwesome;
		right: 5px;
		top: 10px;
		transform: scale(0);
		transition: all .5s;
	}
	&.active a:after {
		content: "\f01a";
		transform: scale(1);
		transition: all .5s;
	}
}
#accordion .panel-heading1 {
	a:before {
		content: "\f01a";
		font-size: $default-value-size * 24;
		position: absolute;
		font-family: FontAwesome;
		right: 5px;
		top: 10px;
		transform: rotate(180deg);
		transition: all .5s;
	}
	&.active a:before {
		transform: rotate(0);
		transition: all .5s;
	}
}
.more-less {
	float: right;
	color: #212121;
}
.panel1 {
	border-width: 0 0 1px 0;
	border-style: solid;
	border-color: $white;
	background: 0 0;
	box-shadow: none;
	&:last-child {
		border-bottom: none;
	}
}
.panel-group1 {
	>.panel1:first-child .panel-heading1 {
		border-radius: 4px 4px 0 0;
	}
	.panel1 {
		border-radius: 0;
	}
}
.card-title1 {
	font-size: $default-value-size * 14;
	margin-bottom: 0;
}
.panel-group1 .panel1+.panel1 {
	margin-top: 0;
}
.panel-heading1 {
	background-color: $white;
	border-radius: 0;
	border: none;
	color: $text-color;
	padding: 0;
}
.panel-group1 .panel-body {
	border-top: 1px solid $border-color;
}
.card-title1 a {
	display: block;
	padding: 1.5rem 1.5rem;
	position: relative;
	font-size: $default-value-size * 16;
	font-weight: $font-weight-semibold;
	&.collapsed {
		color: $text-color;
	}
}
.panel-body1 {
	background: $white;
	padding: 10px;
}
.panel1:last-child {
	.panel-body1 {
		border-radius: 0 0 4px 4px;
	}
	.panel-heading1 {
		border-radius: 0 0 4px 4px;
		transition: border-radius .3s linear .2s;
		&.active {
			border-radius: 0;
			transition: border-radius linear 0s;
		}
	}
}
.accordion {
	h2 {
		line-height: 1.5;
		margin-bottom: 5px !important;
	}
	.card-header {
		padding: 0.75rem 1.5rem;
	}
}
.details-accordion .accordion {
	>.card {
		border-radius: 5px;
		margin-bottom: 0.75rem;
		>.card-header {
			min-height: inherit;
			padding: 1rem 1.5rem;
			padding-right: 42px;
		}
	}
	.card {
		.card-header.collapsed {
			color: $text-color;
		}
		&:not(:first-of-type):not(:last-of-type) {
			border-radius: 5px;
		}
		&:last-child .collapsed {
			border-bottom: 0px solid $border-color !important;
		}
		.card-header {
			&.collapsed:before {
				content: "\e92f";
				position: absolute;
				font-family: feather !important;
				position: absolute;
				right: 15px;
				top: 17px;
				opacity: 0.35;
			}
			&:before {
				content: "\e92d";
				font-family: feather !important;
				position: absolute;
				right: 15px;
				top: 17px;
			}
		}
		&:first-of-type {
			border-bottom: 1px solid $border-color !important;
		}
	}
	>.card>.card-header:hover {
		border-bottom: 1px solid $border-color !important;
		color: $text-color;
	}
	>.card:not(:last-of-type) {
		border-bottom: 1px solid $border-color !important;
	}
}
/*------ accordion -------*/
/*-------- Select2 -------*/
.select2-lg {
	.select2-container .select2-selection--single {
		height: 2.875rem !important;
	}
	.select2-container--default .select2-selection--single .select2-selection__rendered {
		line-height: 45px !important;
	}
}
.select2-sm {
	.select2-container .select2-selection--single {
		height: 1.775rem !important;
	}
	.select2-container--default .select2-selection--single {
		.select2-selection__rendered {
			line-height: 27px !important;
		}
		.select2-selection__arrow b {
			top: 80% !important;
		}
	}
}

.form-group.border-white .select2-container--default .select2-selection--single {
	border: 1px solid $white !important;
}
.search-background .select2-container--default .select2-selection--single {
	border: 1px solid $white !important;
}
.select2-container--default {
	.select2-selection--single {
		background-color: $form-background;
		border: 1px solid $border-color !important;
		border-radius: 5px;
	}
	&.select2-container--focus .select2-selection--multiple {
		color: #3d4e67;
		background-color: $form-background;
		border-color: #4c8aec;
		outline: 0;
	}
	.select2-selection--multiple {
		background-color: $form-background;
		border: 1px solid $border-color !important;
		border-radius: 5px;
		cursor: text;
	}
	.select2-search--dropdown .select2-search__field {
		border: 1px solid $border-color !important;
	}
	.select2-selection--multiple .select2-selection__choice {
		background-color: $border-color !important;
		border: 1px solid $border-color !important;
	}
}

.select2-container--default .select2-selection--single .select2-selection__rendered {
	color: $text-color !important;
	line-height: 39px !important;
}
.select-languages {
	color: $text-color;
	border-radius: 5px;
	&:focus {
		color: #3d4e67;
		background-color: $white;
	}
}
/*-------- Select2 -------*/
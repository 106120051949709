/*-------- Custom Control -------*/

.custom-control {
	position: relative;
	display: block;
	min-height: 1.5rem;
	padding-left: 1.5rem;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}
.custom-control-inline {
	display: -ms-inline-flexbox;
	display: inline-flex;
	margin-right: 1rem;
}
.custom-control-input {
	position: absolute;
	z-index: -1;
	opacity: 0;
	&:checked~.custom-control-label::before {
		color: $white;
	}
	&:active~.custom-control-label::before {
		color: $white;
		background-color: #d4e1f4;
	}
	&:disabled~.custom-control-label {
		color: #868e96;
		&::before {
			background-color: $border-color;
		}
	}
}
.custom-control-label {
	margin-bottom: 0;
	position: static !important;
	vertical-align: middle;
	&::before {
		position: absolute;
		top: .25rem;
		left: 0;
		display: block;
		width: 1rem;
		height: 1rem;
		pointer-events: none;
		content: "";
		-webkit-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		user-select: none;
		background-color: $border-color;
	}
	&::after {
		position: absolute;
		top: .25rem;
		left: 0;
		display: block;
		width: 1rem;
		height: 1rem;
		content: "";
		background-repeat: no-repeat;
		background-position: center center;
		background-size: 50% 50%;
	}
}
.custom-checkbox .custom-control-label::before {
	border-radius: 5px;
}
.br.custom-checkbox .custom-control-label::before {
	border-radius: 50px;
}
.custom-controls-stacked .custom-control {
	margin-bottom: .25rem;
}
.custom-control-label:before {
	border: 1px solid #c6c0de;
	background-color: $white;
	background-size: $space-sm;
}
.custom-control-description {
	line-height: 1.5rem;
}
.dark-checkboxes {
	.custom-control-label {
		line-height: 1.8;
		&:before {
			border: 2px solid$white;
			background-color: rgba($white, 0.1);
			background-size: $space-sm;
		}
	}
}
/*-------- Custom Control -------*/

@import "lib/vendor/daterangepicker";
@import "lib/vendor/flags";
@import "lib/vendor/browser";
@import "lib/vendor/payment";
@import "lib/vendor/gallery";
@import "lib/vendor/jquery.timepicker";
@import "lib/vendor/owl.carousel";
@import "lib/vendor/popup-chat";
@import "lib/vendor/rangeslider";
@import "lib/vendor/select2.min";
@import "lib/vendor/uidate-picker";
@import "lib/vendor/vector-map";
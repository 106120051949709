/*-------- Input groups  -------*/
input {
	&[type=button].btn-block, &[type=reset].btn-block, &[type=submit].btn-block {
		width: 100%;
	}
}
.input-group {
	position: relative;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	-ms-flex-align: stretch;
	align-items: stretch;
	width: 100%;
	> {
		.custom-file, .custom-select, .form-control {
			position: relative;
			-ms-flex: 1 1 auto;
			flex: 1 1 auto;
			width: 1%;
			margin-bottom: 0;
		}
		.custom-file:focus, .custom-select:focus, .form-control:focus {
			z-index: 3;
		}
		.custom-file+ {
			.custom-file, .custom-select, .form-control {
				margin-left: -1px;
			}
		}
		.custom-select+ {
			.custom-file, .custom-select, .form-control {
				margin-left: -1px;
			}
		}
		.form-control+ {
			.custom-file, .custom-select, .form-control {
				margin-left: -1px;
			}
		}
		.custom-select:not(:last-child), .form-control:not(:last-child) {
			border-top-right-radius: 0;
			border-bottom-right-radius: 0;
		}
		.custom-select:not(:first-child), .form-control:not(:first-child) {
			border-top-left-radius: 0;
			border-bottom-left-radius: 0;
		}
		.custom-file {
			display: -ms-flexbox;
			display: flex;
			-ms-flex-align: center;
			align-items: center;
			&:not(:last-child) .custom-file-label {
				border-top-right-radius: 0;
				border-bottom-right-radius: 0;
				&::after {
					border-top-right-radius: 0;
					border-bottom-right-radius: 0;
				}
			}
			&:not(:first-child) .custom-file-label {
				border-top-left-radius: 0;
				border-bottom-left-radius: 0;
				&::after {
					border-top-left-radius: 0;
					border-bottom-left-radius: 0;
				}
			}
		}
	}
}
.input-group-append, .input-group-prepend {
	display: -ms-flexbox;
	display: flex;
}
.input-group-append .btn, .input-group-prepend .btn {
	position: relative;
	z-index: 2;
}
.input-group-append {
	.btn+ {
		.btn, .input-group-text {
			margin-left: -1px;
		}
	}
	.input-group-text+ {
		.btn, .input-group-text {
			margin-left: -1px;
		}
	}
}
.input-group-prepend {
	.btn+ {
		.btn, .input-group-text {
			margin-left: -1px;
		}
	}
	.input-group-text+ {
		.btn, .input-group-text {
			margin-left: -1px;
		}
	}
	margin-right: -1px;
}
.input-group-append {
	margin-left: -1px;
}
.input-group-text {
	display: -ms-flexbox;
	display: flex;
	-ms-flex-align: center;
	align-items: center;
	padding: .375rem .75rem;
	margin-bottom: 0;
	font-size: .9375rem;
	font-weight: $font-weight-normal;
	line-height: 1.6;
	color: #3d4e67;
	text-align: center;
	white-space: nowrap;
	background-color: $border-color;
	border: 1px solid $border-color;
	border-radius: 5px;
	input {
		&[type=checkbox], &[type=radio] {
			margin-top: 0;
		}
	}
}
.input-group> {
	.input-group-append {
		&:last-child> {
			.btn:not(:last-child):not(.dropdown-toggle), .input-group-text:not(:last-child) {
				border-top-right-radius: 0;
				border-bottom-right-radius: 0;
			}
		}
		&:not(:last-child)> {
			.btn, .input-group-text {
				border-top-right-radius: 0;
				border-bottom-right-radius: 0;
			}
		}
	}
	.input-group-prepend> {
		.btn, .input-group-text {
			border-top-right-radius: 0;
			border-bottom-right-radius: 0;
		}
	}
	.input-group-append> {
		.btn, .input-group-text {
			border-top-left-radius: 0;
			border-bottom-left-radius: 0;
		}
	}
	.input-group-prepend {
		&:first-child> {
			.btn:not(:first-child), .input-group-text:not(:first-child) {
				border-top-left-radius: 0;
				border-bottom-left-radius: 0;
			}
		}
		&:not(:first-child)> {
			.btn, .input-group-text {
				border-top-left-radius: 0;
				border-bottom-left-radius: 0;
			}
		}
	}
}
/*-------- Input groups  -------*/